import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c(VCard,[_c(VTabs,{staticClass:"tab-index",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTabItem,{key:item,staticClass:"mt-2"},[_c(VCardTitle,[_vm._v(" Navigation Card ")]),_c(VCardText,{domProps:{"textContent":_vm._s(_vm.text)}}),_c(VCardText,[_c(VBtn,{attrs:{"color":"primary"}},[_vm._v(" Learn More ")])],1)],1)}),1)],1)],1),_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c(VCard,[_c(VTabs,{staticClass:"tab-index",attrs:{"centered":""},model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c(VTabsItems,{model:{value:(_vm.tab2),callback:function ($$v) {_vm.tab2=$$v},expression:"tab2"}},_vm._l((_vm.items),function(item){return _c(VTabItem,{key:item,staticClass:"text-center mt-2"},[_c(VCardTitle,{staticClass:"d-flex justify-center"},[_vm._v(" Navigation Card ")]),_c(VCardText,{domProps:{"textContent":_vm._s(_vm.text)}}),_c(VCardText,[_c(VBtn,{attrs:{"color":"primary"}},[_vm._v(" Learn More ")])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }