import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,_vm._l((_vm.solidCardData),function(data){return _c(VCol,{key:data.icon,attrs:{"cols":"12","md":"6","lg":"4"}},[_c(VCard,{attrs:{"color":data.cardBg,"dark":""}},[_c(VCardTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"1.5rem","color":"white"}},[_vm._v(" "+_vm._s(data.icon)+" ")]),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(data.title))])],1),_c(VCardText,{staticClass:"white--text"},[_vm._v(" "+_vm._s(data.text)+" ")]),_c(VCardActions,{staticClass:"justify-space-between"},[_c('div',[_c(VAvatar,{attrs:{"size":"34"}},[_c(VImg,{attrs:{"src":require(("@/assets/images/avatars/" + (data.avatarImg)))}})],1),_c('span',{staticClass:"white--text ms-2"},[_vm._v(_vm._s(data.avatarName))])],1),_c('div',{staticClass:"white--text"},[_c(VIcon,{staticClass:"me-1",attrs:{"color":"white","size":"1rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiHeart)+" ")]),_c('span',{staticClass:"text-sm me-4"},[_vm._v(_vm._s(data.likes))]),_c(VIcon,{staticClass:"me-1",attrs:{"color":"white","size":"1rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShareVariant)+" ")]),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(data.share))])],1)])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }